
import {
    computed, defineComponent, onMounted, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import Location from '@/domain/Location';
import LocationService from '@/services/LocationService';
import ShippingNoticeService from '@/services/ShippingNoticeService';
import ShippingNotice from '@/domain/ShippingNotice';
import ShippingNoticeItem from '@/domain/ShippingNoticeItem';
import ProductionPart from '@/domain/ProductionPart';
import Carrier from '@/domain/Carrier';
import CarrierService from '@/modules/master-data/services/CarrierService';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import Item from '@/domain/Item';
import ItemService from '@/services/ItemService';
import ProductionPartService from '@/services/ProductionPartService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import resetProp from '@/functions/object';

type State = {
    originalShippingNotice: ShippingNotice | undefined;
    shippingNotice: ShippingNotice | undefined;
    useItemNumber: boolean;
    locations: Array<Location>;
    carriers: Array<Carrier>;
    items: Array<Item>;
    productionParts: Array<ProductionPart>;
    loading: boolean;
    saving: boolean;
};

export default defineComponent({
    name: 'master-data-import-errors-asn-update',
    components: {
        BRow,
        BCol,
        DropdownAutocompleteSingleSelect,
        SmartTrakFooter,
        BSpinner,
        TextInput,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const locationService = new LocationService();
        const carrierService = new CarrierService();
        const shippingNoticeService = new ShippingNoticeService();
        const itemService = new ItemService();
        const productionPartService = new ProductionPartService();

        const { titleCase } = useStringFormatter();
        const router = useRouter();

        const state = reactive<State>({
            originalShippingNotice: undefined,
            shippingNotice: undefined,
            useItemNumber: true,
            locations: [],
            carriers: [],
            items: [],
            productionParts: [],
            loading: false,
            saving: false,
        });

        const pageTitle = computed(() => getTitleCaseTranslation('core.button.updateData') + (state.shippingNotice ? ` (${state.shippingNotice.partnerReferenceNumber})` : ''));

        const itemTableFields = computed(
            (): Array<BTableField<ShippingNoticeItem>> => [
                {
                    key: state.useItemNumber ? 'itemNumber' : 'productionPartNumber',
                    label: getTitleCaseTranslation(state.useItemNumber ? 'core.domain.itemNumber' : 'core.domain.partNumber'),
                    ignoreSort: true,
                },
                {
                    key: 'quantity',
                    label: getTitleCaseTranslation('core.domain.quantity'),
                    ignoreSort: true,
                },
            ],
        );

        function getLocation(locationNumber: string | undefined) {
            return state.locations.find((x) => x.locationNumber?.toLowerCase() === locationNumber?.toLowerCase());
        }

        const billToLocations = computed(() => {
            let selectableBillToLocations = getLocation(state.shippingNotice?.toLocation)?.billToLocations;
            if (!selectableBillToLocations) {
                selectableBillToLocations = [];
            }

            return selectableBillToLocations;
        });

        function locationExists(locationNumber: string | undefined) {
            return !!(locationNumber && state.locations.find((x) => x.locationNumber?.toLowerCase() === locationNumber?.toLowerCase()));
        }

        function carrierExists(scac: string | undefined) {
            return !!(scac && state.carriers.find((x) => x.scac?.toLowerCase() === scac?.toLowerCase()));
        }

        function itemExists(itemNumber: string | undefined) {
            return !!(itemNumber && state.items.find((x) => x.customerItemNumber?.toLowerCase() === itemNumber?.toLowerCase()));
        }

        function partExists(partNumber: string | undefined) {
            return !!(partNumber && state.productionParts.find((x) => x.number?.toLowerCase() === partNumber?.toLowerCase()));
        }

        onMounted(async () => {
            state.loading = true;

            const [locationsResponse, carriersResponse, itemsResponse, productionPartsResponse, noticeReponse] = await Promise.all([
                locationService.getAllLocations(),
                carrierService.getAllCarriers(),
                itemService.getAllItems(),
                productionPartService.getAllProductionParts(),
                shippingNoticeService.getShippingNotice(parseInt(props.id, 10)),
            ]);

            if (locationsResponse.success) {
                state.locations = locationsResponse.locations.filter((x) => x.locationNumber);
            }
            if (carriersResponse.success) {
                state.carriers = carriersResponse.carriers.filter((x) => x.scac);
            }
            if (itemsResponse.success) {
                state.items = itemsResponse.items.filter((x) => x.customerItemNumber);
            }
            if (productionPartsResponse.parts) {
                state.productionParts = productionPartsResponse.parts.filter((x) => x.number);
            }

            if (noticeReponse) {
                // store original values for displaying in labels
                state.originalShippingNotice = new ShippingNotice(noticeReponse);

                state.shippingNotice = noticeReponse;
                // if any items on the notice have an item number, assume we are using items not production parts
                state.useItemNumber = state.shippingNotice.shippingNoticeItems.some((x) => x.itemNumber);

                // clear out any values that are invalid
                if (!locationExists(state.shippingNotice.fromLocation)) {
                    state.shippingNotice.fromLocation = '';
                }
                if (!locationExists(state.shippingNotice.toLocation)) {
                    state.shippingNotice.toLocation = '';
                }
                if (!locationExists(state.shippingNotice.billToLocation) && billToLocations.value.length === 1) {
                    state.shippingNotice.billToLocation = billToLocations.value[0].locationNumber ?? '';
                }
                if (!carrierExists(state.shippingNotice.carrier)) {
                    state.shippingNotice.carrier = '';
                }
                state.shippingNotice.shippingNoticeItems.forEach((x) => {
                    if (x.itemNumber && !itemExists(x.itemNumber)) {
                        x.itemNumber = '';
                    }
                    if (x.productionPartNumber && !partExists(x.productionPartNumber)) {
                        x.productionPartNumber = '';
                    }
                });
            }

            state.loading = false;
        });

        function selectFromLocation(location: Location) {
            if (state.shippingNotice) {
                state.shippingNotice.fromLocation = location?.locationNumber ?? '';
            }
        }

        function selectToLocation(location: Location) {
            if (state.shippingNotice) {
                state.shippingNotice.toLocation = location?.locationNumber ?? '';
            }
        }

        function selectBillToLocation(location: Location) {
            if (state.shippingNotice) {
                state.shippingNotice.billToLocation = location?.locationNumber ?? '';
            }
        }

        function selectCarrier(carrier: Carrier) {
            if (state.shippingNotice) {
                state.shippingNotice.carrier = carrier?.scac ?? '';
            }
        }

        function findOriginalNoticeItem(noticeItemId: number) {
            return state.originalShippingNotice?.shippingNoticeItems?.find((x: ShippingNoticeItem) => x.id === noticeItemId);
        }

        function findOriginalItemNumber(noticeItemId: number) {
            return findOriginalNoticeItem(noticeItemId)?.itemNumber;
        }

        function findOriginalPartNumber(noticeItemId: number) {
            return findOriginalNoticeItem(noticeItemId)?.productionPartNumber;
        }

        function selectItem(shippingNoticeItem: ShippingNoticeItem, item: Item) {
            if (!state.shippingNotice || !shippingNoticeItem) {
                return;
            }

            shippingNoticeItem.itemNumber = item?.customerItemNumber ?? '';
            shippingNoticeItem.productionPartNumber = '';
        }

        function selectPart(shippingNoticeItem: ShippingNoticeItem, part: ProductionPart) {
            if (!state.shippingNotice || !shippingNoticeItem) {
                return;
            }

            shippingNoticeItem.productionPartNumber = part?.number ?? '';
            shippingNoticeItem.itemNumber = '';
        }

        function clearItem(shippingNoticeItem: ShippingNoticeItem) {
            shippingNoticeItem.itemNumber = '';
            shippingNoticeItem.productionPartNumber = '';
        }

        function goToShippingNoticeList() {
            router.push({ name: MasterDataRouteTypes.IMPORT_ERRORS.ASN });
        }

        async function save() {
            if (!state.shippingNotice) {
                return;
            }

            // reset any (editable) empty fields to the original value
            ['fromLocation', 'toLocation', 'carrier'].forEach((x) => resetProp(x, state.shippingNotice, state.originalShippingNotice));
            state.shippingNotice.shippingNoticeItems.forEach((item, index) => {
                ['itemNumber', 'productionPartNumber'].forEach((x) => resetProp(x, item, state.originalShippingNotice?.shippingNoticeItems[index]));
            });

            state.saving = true;
            const response = await shippingNoticeService.updateShippingNotice(state.shippingNotice);
            if (response?.success) {
                goToShippingNoticeList();
            }
            state.saving = false;
        }

        return {
            state,
            pageTitle,
            selectToLocation,
            selectCarrier,
            selectFromLocation,
            titleCase,
            getTitleCaseTranslation,
            getTranslation,
            goToShippingNoticeList,
            save,
            itemTableFields,
            selectItem,
            selectPart,
            clearItem,
            locationExists,
            carrierExists,
            itemExists,
            partExists,
            findOriginalItemNumber,
            findOriginalPartNumber,
            billToLocations,
            selectBillToLocation,
        };
    },
});
